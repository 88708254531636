<template>
	<!-- style="width:100vw" -->
	<div id="main"  v-if="showchart">
		<!-- dark-fresh-cut -->
		
		<!-- <v-chart  :option="option_column2" theme="dark-fresh-cut" :loading="loading" style="height: 82vh" ref="ref_echart2"></v-chart> -->
		
		<v-chart autoresize :option="option_column" theme="dark-fresh-cut" :loading="loading" style="" ref="ref_echart1" ></v-chart>
	</div>
</template>

<script>
	import request from '@/utils/request'
	export default {

		name: 'robot_information',
		data() {
			return {
				user_info: [],
				option_column: {
					title: {text: "Earnings chart"},
					tooltip: {},
					xAxis: {data: ["", "", "", "", "", ""],},
					yAxis: {},
					series: [
						{
							name: "Earnings",
							type: "bar",
							data: [0, 0, 0, 0, 0, 0],
						}, 
					],
				},
				option_column2: {
					title: {text: "Commision chart"},
					
					tooltip: {
					    trigger: 'axis',
					    position: function (pt) {
					      return [pt[0], '1%'];
					    }
					},
					xAxis: {data: ["", "", "", "", "", ""],},
					yAxis: {},
					series: [
						{
							name: "Earnings",
							type: "line",
							
							data: [0, 0, 0, 0, 0, 0],
							smooth: true,
							symbol: 'none',
						}, 
					],
				},
				showchart:false,
				loading:true,
				robot_id:0,
				pagewidth:'',

			};
		},
		activated() {
			//console.log('我这个页面显示就会执行');
			let _this = this;
			//刷新
			if (_this.$route.params.refresh) {
				//_this.$router.go(0)
			}
			console.log(document.documentElement.clientWidth);// 设备屏幕的宽度
			console.log(document.documentElement.clientHeight);// 设备屏幕的宽度
			if(document.documentElement.clientHeight > document.documentElement.clientWidth){
				_this.pagewidth = document.documentElement.clientHeight +'px';
			}

			_this.local_data();

		},
		beforeMount() {
		　　 
      

		},
		mounted() {
			let _this = this;
			window.addEventListener("resize", () => this.$refs?.ref_echart1?.resize());
			window.addEventListener("resize", () => this.$refs?.ref_echart2?.resize());
			let id = _this.$route.params.id > 0 ? _this.$route.params.id : 0 ;
			_this.robot_id = id;
			if(_this.robot_id>0){
				
			}
			
			


		},

		methods: {
			//获取信息
			local_data() {
				let _this = this;
				let token = window.sessionStorage.getItem('token');
				if (window.sessionStorage.getItem('token')) {
					if (window.sessionStorage.getItem('token') == 'undefined') {
						this.hasLogin = 0;
						
						this.$router.replace({
							name: 'Login',
							params: {
								refresh: true
							}
						})
					} else {
						this.hasLogin = 1;
						_this.user_info = JSON.parse(window.sessionStorage.getItem('user_info'));
						//_this.getinformation();
						let id = _this.$route.params.id > 0 ? _this.$route.params.id : 0 ;
						_this.robot_id = id;
						
						_this.getProfitList(id)
					
						console.log(id)
					}
				} else {
					this.hasLogin = 0;
					
					this.$router.replace({
						name: 'Login',
						params: {
							refresh: true
						}
					})
				}
			},
			timestampToTime (timestamp) {
			       // 计算年月日时分的函数
			       var date = new Date(timestamp)
			       var Y = date.getFullYear() + '-'
			       var M = (date.getMonth() + 1) + '-'
			       var D = date.getDate() + ' '
			       var h = date.getHours() + ':'
			       var m = date.getMinutes() + ':'
			       var s = date.getSeconds()
			       return Y + M + D 
			},
			//robot/getProfitList
			//机器人利润列表 参数id
			getProfitList(id){
				let _this = this;
				let token = window.sessionStorage.getItem('token');
				request.post('index/getUserProfitList', {
					auth: token,
					id:id,
				}).then(res => {
					//console.log(res)
					//结果
					if(res.data.errno ==0){
						
						_this.showchart = true;
						_this.loading = false;
						let balanceList = res.data.data.profitList;
						let datetime = [];
						let profit = [];
	
						for(let i=0;i<balanceList.length;i++){
							
							    
							datetime.push(balanceList[i].creation_date);
							profit.push(balanceList[i].countProfit);
							
						}
						
						_this.option_column.title.text = '总收益图表';
						_this.option_column.xAxis.data = datetime;
						_this.option_column.series[0].name = _this.$t('information.seriesname');
						_this.option_column.series[0].data = profit;
						
						
					}else{
						
						_this.$message({
						    message: _this.$t('disc.Please log in'),
						    type: 'warning'
						});
					}
				});
			},
			//收益列表 
			getinformation() {
				let _this = this;
				request.post('account/getBalanceList', {

				}).then(res => {
					//console.log(res)
					
					if (res.data.errno == 0) {
						_this.showchart = true;
						_this.loading = false;
						let balanceList = res.data.data;
						let datetime = [];
						let profit = [];
						let total_profit = [];
						let commision = [];
						for(let i=0;i<balanceList.length;i++){
							datetime.push(balanceList[i].create_time);
							profit.push(balanceList[i].profit);
							total_profit.push(balanceList[i].total_profit);
							commision.push(balanceList[i].commision);
						}
						
						_this.option_column.title.text = _this.$t('information.title');
						_this.option_column.xAxis.data = datetime;
						_this.option_column.series[0].name = _this.$t('information.seriesname');
						_this.option_column.series[0].data = total_profit;
						
						_this.option_column2.title.text = _this.$t('information.title2');
						_this.option_column2.xAxis.data = datetime;
						_this.option_column2.series[0].name = _this.$t('information.seriesname2');
						_this.option_column2.series[0].data = profit;
						
						
						
						
					} else {
						_this.$message({
							message: _this.$t('notify.error'),
							type: 'warning'
						});
					}

				});
			},





		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
/* 竖屏 */
@media screen and (orientation: portrait) {
 
	#main {
		position: absolute;
		width: 100vh;
		height: 100vw;
		top: 0;
		left: 100vw;
		-webkit-transform: rotate(90deg);
		-moz-transform: rotate(90deg);
		-ms-transform: rotate(90deg);
		transform: rotate(90deg);
		transform-origin: 0% 0%;
	}
	.echarts{
		/* height: 60vh; */
		padding : 0 0 0 60px;
		
	}
}
/* 横屏 */
@media screen and (orientation: landscape) {
	#main {
		position: absolute;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
	}
	.echarts{
		height: 82vh;
		margin-top: 60px;
	}
}

.item_list{
	width: 100%;
	height: auto;
}



</style>
